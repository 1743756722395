.App {

    display: flex;
    flex-direction: column;
}

.title {
    flex-shrink: 1;
}

.sidebar-context {
    flex-grow: 1;
}

h1, h2, h3, h4, h5 {
    color: #00274C;
}