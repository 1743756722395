.sidebar {
    flex: 0 0 150px;
}

.sidebar-context {
    display: flex;
    flex-direction: row;
}

.sidebar-context .content {
    flex-grow: 1;
}

.sidebar a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
}

.sidebar a.active {
    color: #00274C;
    font-weight: bolder;
}

.sidebar a:hover:not(.active) {
    color: #00274C;
}

@media screen and (max-width: 700px) {
    .sidebar {
        display: none;
    }
    .sidebar-context .content {
            padding: 30px;
    }
}