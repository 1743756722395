.icons {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    gap: 30px;
    flex-wrap: wrap;

    margin-bottom: 30px;
}

.contact-icon {
    width: 300px;
    height: 100px;
    display: inline-block;
    vertical-align: top;

    border: solid black thin;
    border-radius: 20px;

    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-title {
    font-weight: bold;
}