.home {
    display: flex;
    flex-direction: row;
}
.home-text {
    flex: 1;
}
.home-image {
    flex: 1;
}

.resume {
    padding: 20px;
    margin-top: 30px;
    text-align: center;
    border-radius: 20px;
    font-size: larger;
    background-color: #00274C;
    cursor: pointer;
}
a .resume {
    text-decoration: none;
    color: #FFCB05;
    font-weight: bold;
}